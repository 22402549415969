import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import axios from '../../../utils/axios'
import {
	AML_API_URL,
	COMPANY_API_URL,
	COMPANY_FINVOICE_ADDRESS_TYPES,
	DECISION_API_URL,
} from '../../../config/constants'

import {
	Box,
	Breadcrumbs,
	Card,
	CardHeader,
	Divider,
	LinearProgress,
	Paper,
	Typography
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link } from 'react-router-dom'

import Error from '../../../layouts/Main/components/Error'
import CompanyKeyFigures from './components/CompanyKeyFigures'
import { HistoryTable } from '../../../components/history'
import {
	CompanyContactInfo,
	CompanyDocuments,
	CompanyDomains,
	CompanyGeneral,
	CompanyManagement,
} from './components'
import { SnackbarContext } from '../../../context'
import CompanyAmlPersons from './components/CompanyAmlPersons'
import CompanyInvoiceAddress from './components/CompanyInvoiceAddress'

const ResultDetail = () => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const params = useParams()

	const [result, setResult] = useState(null)


	const [persons, setPersons] = useState([])
	const [histories, setHistories] = useState([])

	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(true)
	const [refreshing, setRefreshing] = useState(false)

	const [country, setCountry] = useState('fi')

	console.log({
		result,
		persons,
		histories,
		error
	});

	useEffect(() => {
		load()
		loadHistory()
	}, [params.id])

	const loadHistory = async () => {
		try {
			const { data } = await axios.get(`/company_history?company_id=eq.${params.id}`)

			setHistories(data)
		} catch (error) {}
	}

	const loadPersons = async (id) => {
		try {
			const { data } = await axios.get(`/person?company_id=eq.${params.id}`)
			setPersons(data)
		} catch (error) {}
	}

	const load = async () => {
		setLoading(true)

		try {
			console.log(params.id)
			const { data } = await axios.get(`${COMPANY_API_URL}?uuid=eq.${params.id}&select=*,company_address(*)`)
			console.log({data});
			setResult(data[0])

			setCountry(data[0].primary_business_id?.indexOf('-') > -1 ? 'fi' : 'no')
		} catch (error) {
			console.log({error});
			setError(true)
		}

		setLoading(false)
	}

	const setResponsiblePersons = (data) => {
		setResult({
			...result,
			data: {
				...result.data,
				responsiblePersons: data?.data?.responsiblePersons
			}
		})
	}

	const refreshData = async () => {
		setRefreshing(true)

		try {
			const { data: companyData } = await axios.get(`${COMPANY_API_URL}?uuid=eq.${params.id}`)
			setResult(companyData)

			const id = companyData.id
			const { data: decisionData } = await axios.get(
				`${DECISION_API_URL}?uuid=eq.${params.id}`
			)
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setRefreshing(false)
	}

	const handleRefresh = async () => {
		setLoading(true)
		try {
			await axios.get(`${DECISION_API_URL}/company/base`, {
				params: {
					businessId: result.primary_business_id,
					refresh: true,
					country: country
				}
			})

			await refreshData()
		} catch (error) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	const loadAmlData = async () => {
		setRefreshing(true)

		try {
			await axios.get(`${AML_API_URL}/company/comprehensive`, {
				params: {
					businessId: result.primary_business_id,
					country: country
				}
			})

			await loadPersons()
		} catch (e) {
			showMessage(t('company.amlNotAvailable'), 'error')
		}

		setRefreshing(false)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems={'center'}>
				<Box>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h3" component="h3" gutterBottom={true}>
							{result && result.name}
						</Typography>
					</Box>

					<Breadcrumbs
						separator={<NavigateNextIcon fontSize="small" />}
						aria-label="breadcrumb"
					>
						<Link color="inherit" variant={'subtitle2'} to="/">
							{t('nav.companies')}
						</Link>
						<Typography variant={'subtitle2'} color="textPrimary">
							{t('nav.company')}
						</Typography>
					</Breadcrumbs>
				</Box>
			</Box>

			<Box mt={4}>
				{error ? (
					<Error />
				) : loading ? (
					<LinearProgress />
				) : (
					<Box mb={6} display={'flex'} flexDirection={'column'} gridRowGap={30}>
						<CompanyGeneral
							result={result}
							handleRefresh={handleRefresh}
							refreshing={refreshing}
						/>

						<CompanyContactInfo result={result} />

						<CompanyInvoiceAddress
							addresses={result.finvoiceAddresses}
							titleKey={'receivingFinvoiceAddress'}
							type={COMPANY_FINVOICE_ADDRESS_TYPES.receiving}
						/>

						<CompanyInvoiceAddress
							addresses={result.finvoiceAddresses}
							titleKey={'sendingFinvoiceAddress'}
							type={COMPANY_FINVOICE_ADDRESS_TYPES.sending}
						/>

						<CompanyKeyFigures
							data={result.companyKeyFigures}
							businessId={result.primary_business_id}
							afterDataRefresh={refreshData}
							refreshing={refreshing}
							setRefreshing={setRefreshing}
							country={country}
						/>

						<CompanyKeyFigures
							data={result.companyKeyFigures}
							businessId={result.primary_business_id}
							afterDataRefresh={refreshData}
							refreshing={refreshing}
							setRefreshing={setRefreshing}
							isConsolidated={true}
							country={country}
						/>

						<CompanyManagement
							businessId={result.primary_business_id}
							data={result.responsiblePersons}
							setData={setResponsiblePersons}
							refreshing={refreshing}
							setRefreshing={setRefreshing}
							country={country}
						/>

						<CompanyAmlPersons
							data={persons}
							loadAmlData={loadAmlData}
							loading={refreshing}
						/>

						<CompanyDomains
							businessId={result.primary_business_id}
							companyId={result.uuid}
							companyName={result.name}
						/>

						<CompanyDocuments businessId={result.primary_business_id} />

						<Card component={Paper}>
							<CardHeader title={t('history')} />

							<Divider />
							<HistoryTable histories={histories} />
						</Card>
					</Box>
				)}
			</Box>
		</>
	)
}

export default ResultDetail
